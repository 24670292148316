// lodash
import { cloneDeep } from 'lodash';
import { gql } from '@/lib/response/__generated__';
import { useQuestionnaire, useResponseActions } from '@/store/response';
import { NumberOrString, Section } from '@/types/response';
import { responseClient } from '@/utils/apollo-client';
import { useMutation } from '@apollo/client';

function findQuestionById(sections: Section[], questionId: NumberOrString) {
  for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
    const section = sections[sectionIndex];
    for (let questionIndex = 0; questionIndex < section.questions.length; questionIndex++) {
      const question = section.questions[questionIndex];
      if (question?.id === questionId) {
        return { sectionIndex, questionIndex };
      }
    }
  }
  return { sectionIndex: -1, questionIndex: -1 };
}

export function useDeleteRow(leadingQuestionId: NumberOrString, rowId: NumberOrString) {
  const { questionnaire } = useQuestionnaire();
  const { setQuestionnaire, cleanAfterRowDeletion } = useResponseActions();

  const add = gql(`
  mutation deleteRow($id: String!){
    deleteRow(id: $id)
  }
  `);

  return useMutation<{ id: NumberOrString }>(add, {
    client: responseClient,
    variables: { id: rowId },
    update() {
      const instance = cloneDeep(questionnaire);

      const { sectionIndex, questionIndex } = findQuestionById(instance?.sections as Section[], leadingQuestionId);

      if (sectionIndex !== -1) {
        const section = instance.sections[sectionIndex];
        const question = section?.questions[questionIndex];

        // Ensure that question.type is of QuestionTypeComplex and rows exist
        if (question?.type.__typename === 'QuestionTypeMatrix' && question.type.rows) {
          question.type.rows = question.type.rows.filter(row => row.id !== rowId);
        }

        cleanAfterRowDeletion(leadingQuestionId, [
          rowId,
        ]);
        setQuestionnaire(instance);
      }
    },
  });
}

export function useDeleteRowsAfter(leadingQuestionId: NumberOrString) {
  const { questionnaire } = useQuestionnaire();
  const { setQuestionnaire, cleanAfterRowDeletion } = useResponseActions();
  const deleteRowsAfter = gql(`
    mutation deleteRowsAfter($id: String!){
      deleteRowsAfter(id: $id)
    }
  `);

  return useMutation(deleteRowsAfter, {
    client: responseClient,
    update(_, { data }) {
      if (!data?.deleteRowsAfter) return;
      const { deleteRowsAfter } = data;
      const instance = cloneDeep(questionnaire);
      const { sectionIndex, questionIndex } = findQuestionById(instance?.sections as Section[], leadingQuestionId);
      if (sectionIndex !== -1) {
        const section = instance.sections[sectionIndex];
        const question = section?.questions[questionIndex];
        // Ensure that question.type is of QuestionTypeComplex and rows exist
        if (question?.type.__typename === 'QuestionTypeMatrix' && question.type.rows) {
          question.type.rows = question.type.rows.filter(row => !deleteRowsAfter.includes(row.id));
        }
        cleanAfterRowDeletion(leadingQuestionId, deleteRowsAfter);
        setQuestionnaire(instance);
      }
    },
  });
}

export function useDeleteRowPreviewMode(questionId: NumberOrString) {
  const { questionnaire } = useQuestionnaire();
  const { setQuestionnaire, cleanAfterRowDeletion } = useResponseActions();

  const deleteRowPreview = (rowId: string) => {
    const { sectionIndex, questionIndex } = findQuestionById(questionnaire?.sections as Section[], questionId);
    if (sectionIndex !== -1 && questionIndex !== -1) {
      const question = questionnaire?.sections[sectionIndex]?.questions[questionIndex];
      if (question?.type.__typename === 'QuestionTypeMatrix' && question.type.rows) {
        question.type.rows = question.type.rows.filter(row => row.id !== rowId);
      }
      cleanAfterRowDeletion(questionId, [
        rowId,
      ]);
      setQuestionnaire({ ...questionnaire });
    }
  };

  return { deleteRowPreview };
}
